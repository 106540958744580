@font-face {
    font-family: "louize-font";
    src: url("./louize-regital.woff2") format("woff2"),
        url("./louize-regital.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "louize-font";
    src: url("./louize-reg.woff2") format("woff2"),
        url("./louize-reg.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
